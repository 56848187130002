import React, { useState } from 'react';

import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

import { Button } from '@components/Button';
import Select, { SelectOptions } from '@components/Formik/Select';

import { CustomLabel } from './CustomLabel';

import { useFetchRegionalGroupDistricts } from '@repositories/delegation/hooks/useFetchRegionalGroupDistricts';

import { localizedNavigate } from '@utils/localized-navigate';

import { useTranslation } from '@external/react-i18next';

import { GetBiReportOrgOptionsQuery } from '@typings/operations';

interface Props {
  oktaId: string;
  reportTechnicalName: string;
  options: GetBiReportOrgOptionsQuery['options'];
}

const validationSchema = Yup.object().shape({
  selectedOption: Yup.string().required('Please select an option'),
});

interface FormValues {
  selectedOption: string;
  districts?: { [key: string]: string };
}

const DataSelectorDistrictOptions: React.FC<Props> = ({
  oktaId,
  reportTechnicalName,
  options,
}) => {
  const { t } = useTranslation();

  const [fetchRegionalGroupDistricts] = useFetchRegionalGroupDistricts();

  const [currentOptions, setCurrentOptions] = useState<SelectOptions>([]);
  const [loading, setLoading] = useState(false);

  const handleRadioChange = async (
    selectedValue: string,
    regionalGroupId: string,
    setFieldValue: (field: string, value: unknown) => void
  ) => {
    setFieldValue('selectedOption', selectedValue);
    setFieldValue('districts', {}); // Reset the dropdown if any

    if (regionalGroupId) {
      setLoading(true);
      const { data, loading } = await fetchRegionalGroupDistricts({
        variables: { regionalGroupId },
      });
      setLoading(loading);
      setCurrentOptions(
        data?.regionalGroupDistricts.map(({ DistrictId }) => ({
          value: DistrictId as string,
          label: DistrictId as string,
        })) || []
      );
    }
  };

  const handleSubmit = async (values: FormValues) => {
    const { selectedOption, districts } = values;

    const selectedDistrictId = districts?.[selectedOption]
      ? districts[selectedOption].split('-')[0]
      : selectedOption.split('-')[0];

    localizedNavigate(
      `/club-and-district-reports/${oktaId}/${reportTechnicalName}/district?districtId=${selectedDistrictId}`
    );
  };

  return (
    <Formik
      initialValues={{ selectedOption: '', districts: {} }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ setFieldValue, values }) => (
        <Form>
          {options.map((option, index) => {
            const uniqueId = `${option.roleName.replace(/\s+/g, '-')}-${index}`;
            const uniqueValue = `${option.districtId}-${index}`;
            const fieldId = `radio-${uniqueId}`;
            const isSelected = values.selectedOption === uniqueValue;

            return (
              <div key={uniqueId}>
                <div className="flex flex-col py-7 border-b border-gray-350 relative">
                  <label
                    className="flex w-full cursor-pointer relative"
                    htmlFor={fieldId}
                  >
                    <Field
                      id={fieldId}
                      type="radio"
                      name="selectedOption"
                      value={uniqueValue}
                      checked={isSelected}
                      onChange={() =>
                        handleRadioChange(
                          uniqueValue,
                          option.regionalGroupId,
                          setFieldValue
                        )
                      }
                      className="w-0"
                    />
                    <div className="w-full">
                      <div className="RadioLabel">
                        <div className="pl-5px -mt-3px">
                          <CustomLabel data={option} />
                        </div>
                      </div>
                    </div>
                  </label>
                  {option.matchType === 'region' && (
                    <div className="w-4/5 mobile-l:w-250 desktop:w-1/3 tablet:ml-8 tablet:-mt-4 absolute tablet:static left-31 tablet:left-0 bottom-20 tablet:bottom-0">
                      <Select
                        name={`districts.${uniqueValue}`}
                        options={isSelected ? currentOptions : []}
                        label={t(
                          'data-selector.regional-districts',
                          'District'
                        )}
                        placeholder={t(
                          'data-selector.select-district',
                          'Select district...'
                        )}
                        clearable
                        loading={loading}
                      />
                    </div>
                  )}
                </div>
              </div>
            );
          })}

          <Button
            type="submit"
            className="mt-12 p-4 rounded-md text-white disabled:bg-gray-300 bg-bright-blue-600 text-sm w-230 max-w-230 min-w-230 h-50px max-h-50px min-h-50px"
            disabled={values.selectedOption === ''}
          >
            {t('continue', 'Continue')}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default DataSelectorDistrictOptions;
