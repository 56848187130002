import React from 'react';

import classNames from 'classnames';

import { getFormattedDate } from '@utils/datetime';
import { getClubRoleTValue } from '@utils/getClubRoleTValue';
import { getQueryParams } from '@utils/query-params';

import { useTranslation } from '@external/react-i18next';

import { GetBiReportOrgOptionsQuery } from '@typings/operations';

interface CustomLabelProps {
  data: GetBiReportOrgOptionsQuery['options'][number];
}
export const CustomLabel: React.FC<CustomLabelProps> = ({ data }) => {
  const { t } = useTranslation();

  const { dataSelector } = getQueryParams();

  const startDateText = t('data-selector.verify-continue-start', 'Start');
  const formattedStartDate = getFormattedDate(
    data.termStartDate,
    'DD MMMM YYYY'
  );
  const startDateLabel = `${startDateText}: ${formattedStartDate}`;

  const endDateText = data.termEndDate
    ? t('data-selector.verify-continue-end', 'End')
    : '';
  const formattedEndDate = data.termEndDate
    ? getFormattedDate(data.termEndDate, 'DD MMMM YYYY')
    : '';
  const endDateLabel =
    endDateText && formattedEndDate
      ? `${endDateText}: ${formattedEndDate}`
      : '';

  const addBottomSpacing =
    (dataSelector === 'district' && data.matchType === 'region') ||
    (dataSelector === 'club' && data.matchType === 'district');

  return (
    <div className="flex flex-col tablet:flex-row w-full tablet:gap-17 text-xs">
      <div className="tablet:basis-1/3 m-0">
        <div
          className={classNames('', {
            'pb-21 tablet:pb-0': addBottomSpacing,
          })}
        >
          <div className="font-bold m-0 text-gray-700">
            {getClubRoleTValue(t, data.roleName)}
          </div>
          {data.clubName && <div>{data.clubName}</div>}
        </div>
      </div>
      <div className="mt-5 tablet:mt-0 tablet:basis-2/3">
        {data.matchType === 'region' ? (
          <div> {data.regionalGroupName} </div>
        ) : (
          <div>
            {t('data-selector.verify-continue-district', 'District')}{' '}
            {data.districtId}
          </div>
        )}
        <div>{`${startDateLabel} ${endDateLabel}`}</div>
      </div>
    </div>
  );
};
